/**
 * Jspreadsheet XLSX render 1.3.2
 * https://jspreadsheet.com/v8/plugins/render
 * @License: this plugin requires a license
 */

if (! JSZip && typeof(require) === 'function') {
    var JSZip = require('jszip');
}

if (! jSuites && typeof(require) === 'function') {
    var jSuites = require('jsuites');
}

var $jscomp=$jscomp||{};$jscomp.scope={};$jscomp.ASSUME_ES5=!1;$jscomp.ASSUME_NO_NATIVE_MAP=!1;$jscomp.ASSUME_NO_NATIVE_SET=!1;$jscomp.SIMPLE_FROUND_POLYFILL=!1;$jscomp.ISOLATE_POLYFILLS=!1;$jscomp.FORCE_POLYFILL_PROMISE=!1;$jscomp.FORCE_POLYFILL_PROMISE_WHEN_NO_UNHANDLED_REJECTION=!1;$jscomp.defineProperty=$jscomp.ASSUME_ES5||"function"==typeof Object.defineProperties?Object.defineProperty:function(g,k,l){if(g==Array.prototype||g==Object.prototype)return g;g[k]=l.value;return g};
$jscomp.getGlobal=function(g){g=["object"==typeof globalThis&&globalThis,g,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var k=0;k<g.length;++k){var l=g[k];if(l&&l.Math==Math)return l}throw Error("Cannot find global object");};$jscomp.global=$jscomp.getGlobal(this);$jscomp.IS_SYMBOL_NATIVE="function"===typeof Symbol&&"symbol"===typeof Symbol("x");$jscomp.TRUST_ES6_POLYFILLS=!$jscomp.ISOLATE_POLYFILLS||$jscomp.IS_SYMBOL_NATIVE;$jscomp.polyfills={};
$jscomp.propertyToPolyfillSymbol={};$jscomp.POLYFILL_PREFIX="$jscp$";var $jscomp$lookupPolyfilledValue=function(g,k){var l=$jscomp.propertyToPolyfillSymbol[k];if(null==l)return g[k];l=g[l];return void 0!==l?l:g[k]};$jscomp.polyfill=function(g,k,l,n){k&&($jscomp.ISOLATE_POLYFILLS?$jscomp.polyfillIsolated(g,k,l,n):$jscomp.polyfillUnisolated(g,k,l,n))};
$jscomp.polyfillUnisolated=function(g,k,l,n){l=$jscomp.global;g=g.split(".");for(n=0;n<g.length-1;n++){var t=g[n];if(!(t in l))return;l=l[t]}g=g[g.length-1];n=l[g];k=k(n);k!=n&&null!=k&&$jscomp.defineProperty(l,g,{configurable:!0,writable:!0,value:k})};
$jscomp.polyfillIsolated=function(g,k,l,n){var t=g.split(".");g=1===t.length;n=t[0];n=!g&&n in $jscomp.polyfills?$jscomp.polyfills:$jscomp.global;for(var F=0;F<t.length-1;F++){var L=t[F];if(!(L in n))return;n=n[L]}t=t[t.length-1];l=$jscomp.IS_SYMBOL_NATIVE&&"es6"===l?n[t]:null;k=k(l);null!=k&&(g?$jscomp.defineProperty($jscomp.polyfills,t,{configurable:!0,writable:!0,value:k}):k!==l&&(void 0===$jscomp.propertyToPolyfillSymbol[t]&&(l=1E9*Math.random()>>>0,$jscomp.propertyToPolyfillSymbol[t]=$jscomp.IS_SYMBOL_NATIVE?
$jscomp.global.Symbol(t):$jscomp.POLYFILL_PREFIX+l+"$"+t),$jscomp.defineProperty(n,$jscomp.propertyToPolyfillSymbol[t],{configurable:!0,writable:!0,value:k})))};$jscomp.polyfill("Array.prototype.includes",function(g){return g?g:function(k,l){var n=this;n instanceof String&&(n=String(n));var t=n.length;l=l||0;for(0>l&&(l=Math.max(l+t,0));l<t;l++){var F=n[l];if(F===k||Object.is(F,k))return!0}return!1}},"es7","es3");$jscomp.owns=function(g,k){return Object.prototype.hasOwnProperty.call(g,k)};
$jscomp.polyfill("Object.entries",function(g){return g?g:function(k){var l=[],n;for(n in k)$jscomp.owns(k,n)&&l.push([n,k[n]]);return l}},"es8","es3");
(function(g,k){"object"===typeof exports&&"undefined"!==typeof module?module.exports=k():"function"===typeof define&&define.amd?define(k):g.render=k()})(this,function(){var g=null,k=new JSZip,l=function(){var a=function(c){a.license=c;a.run()};a.k="745048504833";a.apply=function(c,b){var d=jSuites.sha512,h="",p="";128<c.length&&(c=d(c));for(var r=0;128>r;r++){var m=c[r]?c[r].charCodeAt(0):0;h+=String.fromCharCode(54^m);p+=String.fromCharCode(92^m)}return d(p+d(h+b))};a.run=function(){if("undefined"==
typeof a.result){a.result=1;try{if(a.license){var c=window.atob(a.license);c=c.split(",");if(c[1])if(c[1]=window.atob(c[1]),c[0]!=a.apply(a.k,c[1]))a.result=3;else if(c[1]=JSON.parse(c[1]),c[1].date){var b=window.location.hostname||"localhost",d=b.split(".");b=b.split(".");2<b.length&&!jSuites.isNumeric(b[b.length-1])&&d.shift();d=d.join(".");b=b.join(".");-1==c[1].domain.indexOf(d)&&-1==c[1].domain.indexOf(b)?a.result=4:c[1].scope&&0<=c[1].scope.indexOf("render")?(a.result=6,a.name=c[1].name):a.result=
5}else a.result=4;else a.result=2}}catch(h){}}return a.result};a.label=function(){var c="";a.result&&1!=a.result?2==a.result||3==a.result||4==a.result?c="A valid license is required":5==a.result&&(c="This plugin is not included on the scope of this license"):c="License required";return c};a.status=function(){return a.result};return a}(),n=1,t=1,F=function(a,c){var b='<?xml version="1.0" encoding="UTF-8"?>';b+='<workbook xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">';
b+="<sheets>";for(var d=0;d<a.worksheets.length;d++){var h=a.worksheets[d].options.worksheetName;h||(h="Sheet"+(d+1));b+='<sheet name="'+h+'" sheetId="'+(d+1)+'" r:id="rId'+(d+1)+'" />'}b+="</sheets>";a.config&&a.config.definedNames&&(b+="<definedNames>",Object.keys(a.config.definedNames).forEach(function(p){b+='<definedName name="'+p+'">'+a.config.definedNames[p]+"</definedName>"}),b+="</definedNames>");b+="</workbook>";c.file("xl/workbook.xml",b)},L=function(a,c,b){b.file("xl/styles.xml")&&(a+=
'<Relationship Id="rId'+(c+1)+'" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/styles" Target="styles.xml" />');b.file("xl/_rels/workbook.xml.rels",a+"</Relationships>")},W=function(a,c,b){b.file("[Content_Types].xml",a+'<Override PartName="/xl/styles.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.styles+xml"/>'+c+"</Types>")},I=[null],M=[null,null],J=[null],K=[null],N=[],O=[],Q=[],B=function(a,c){for(var b=a.indexOf(c);0<b&&"-"===a.charAt(b-
1);)b=a.indexOf(c,b+c.length);return-1<b?(b+=c.length+1,c=a.indexOf(";",b),0>c&&(c=a.length),a.slice(b,c).trim()):null},X=function(a,c){var b={},d=a.style[c],h=B(d,"background-color");h&&(b.fill=M.indexOf(h),0>b.fill&&(b.fill=M.length,M.push(h)));if(d.includes("font")){var p={};if(h=B(d,"font-size"))p.fontSize=h.slice(0,-2);if(h=B(d,"font-family"))p.fontName=h;B(d,"font-weight")&&(p.bold=!0);B(d,"font-style")&&(p.italic=!0);if(h=B(d,"color"))p.color=h;for(var r=Object.keys(p),m=1;m<J.length;m++){var f=
J[m];h=Object.keys(f);if(h.length===r.length&&h.every(function(q){return r.includes(q)})&&(h=h.every(function(q){return f[q]===p[q]}))){b.font=m;break}}void 0===b.font&&(b.font=J.length,J.push(p))}if(d.includes("border")){var e={};m=["left","right","top","bottom"];m.forEach(function(q){e[q]={};var z=B(d,"border-"+q+"-style");z&&(e[q].style=z);if(z=B(d,"border-"+q+"-color"))e[q].color=z;if(z=B(d,"border-"+q+"-width"))e[q].width=z});for(var v=m.map(function(q){return Object.keys(e[q])}),u=1;u<I.length;u++){var w=
I[u];if(h=m.every(function(q,z){var G=Object.keys(w[q]);return G.length!==v[z].length?!1:v[z].every(function(C){return G.includes(C)})?v[z].every(function(C){return e[q][C]===w[q][C]}):!1})){b.border=u;break}}void 0===b.border&&(b.border=I.length,I.push(e))}if(h=B(d,"text-align"))b.horizontalAlign=N.indexOf(h),0>b.horizontalAlign&&(b.horizontalAlign=N.length,N.push(h));B(d,"white-space")&&(b.wrapText=Q.indexOf(!0),0>b.wrapText&&(b.wrapText=Q.length,Q.push(!0)));if(a=a.cells[c]){var A=null;if(A=a.format)a=
O.find(function(q){return q[1]===A}),a||(a=[O.length+164,A],O.push(a)),b.format=a[0]}if(b){a=Object.keys(b);c=void 0;for(m=1;m<K.length;m++){var D=K[m];h=Object.keys(D);if(h.length===a.length&&(h=h.every(function(q){return D[q]===b[q]}))){c=m;break}}void 0===c&&(c=K.length,K.push(b));return c}},R=function(){return"xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g,function(a){var c=16*Math.random()|0;return("x"==a?c:c&3|8).toString(16)})},Z=function(a,c,b){var d=a.options,h=a.getData(null,!0,null,
!1),p=h.length-1,r=h[p].length-1;a.minDimensions&&(a.minDimensions[0]>r&&(r=a.minDimensions[0]),a.minDimensions[1]>lastRow&&(lastRow=a.minDimensions[1]));p='<dimension ref="A1:'+g.helpers.getColumnNameFromCoords(r,p)+'"/>';r="<cols>";for(var m=d.columns[0].width,f=1,e=1;e<d.columns.length;e++){var v=d.columns[e].width;v!==m&&(r+='<col min="'+f+'" max="'+e+'" width="'+parseFloat(m)/7.15+'"/>',f=e+1,m=v)}r+='<col min="'+f+'" max="'+d.columns.length+'" width="'+parseFloat(m)/7.15+'"/>';r+="</cols>";
m="";v=document.createElement("div");f=[];for(e=0;e<h.length;e++){for(var u="",w=0;w<h[e].length;w++){var A=g.helpers.getColumnNameFromCoords(w,e);v.innerHTML=h[e][w];var D=v.innerText;D=D.replace(/&/g,"&#38;");D=D.replace(/</g,"&lt;");D=D.replace(/(\r\n|\n|\r)/gm,"\r\n");v.innerHTML=d.data[e][w];var q=v.innerText;q=q.replace(/&/g,"&#38;");q=q.replace(/</g,"&lt;");q=q.replace(/(\r\n|\n|\r)/gm,"");if(""!==d.data[e][w]||d.style&&d.style[A]){u+='<c r="'+A+'"';if(d.style&&d.style[A]){var z=X(d,A);u+=
' s="'+z+'"'}z=a.getColumnOptions(w,e);"data:image"==(""+d.data[e][w]).substr(0,10)?(u+=">",f.push({x:w,y:e,cellName:A,content:d.data[e][w]})):u="="===(""+q).substr(0,1)?u+("><f>"+q.slice(1)+"</f>"):""!==D?"dropdown"==z.type||"record"==z.type||Number(d.data[e][w])!=d.data[e][w]?u+(' t="str"><v>'+D+"</v>"):u+("><v>"+d.data[e][w]+"</v>"):u+">";u+="</c>"}}""!==u&&(m+='<row r="'+(e+1)+'"',d.rows[e]&&d.rows[e].height&&(w=parseInt(parseInt(d.rows[e].height)/1.333333333),m+=' ht="'+w+'" customHeight="1"'),
m+=">",m+=u,m+="</row>")}var G='<?xml version="1.0" encoding="UTF-8" standalone="yes"?>';G+='<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">';var C=1;if(d.cells){e=Object.keys(d.cells).filter(function(x){x=d.cells[x];return"url"===x.type&&x.href});var P="";0<e.length&&(P="<hyperlinks>",e.forEach(function(x){P+='<hyperlink ref="'+x+'" r:id="rId'+C+'"/>';G+='<Relationship Id="rId'+C+'" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/hyperlink" Target="'+
d.cells[x].href+'" TargetMode="External"/>';C++}),P+="</hyperlinks>")}var S="",T="";0<f.length&&f.forEach(function(x){var y=x.x,Y=x.y,E=x.cellName,H=d.cells[E]&&d.cells[E].options&&d.cells[E].options.width?9525*parseInt(d.cells[E].options.width):d.columns[y].width?9525*parseInt(d.columns[y].width):9525*parseInt(d.defaultColWidth);E=d.cells[E]&&d.cells[E].options&&d.cells[E].options.height?9525*parseInt(d.cells[E].options.height):.75*H;y='<?xml version="1.0" encoding="UTF-8" standalone="yes"?><xdr:wsDr xmlns:xdr="http://schemas.openxmlformats.org/drawingml/2006/spreadsheetDrawing" xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main"><xdr:oneCellAnchor><xdr:from><xdr:col>'+
(y+"</xdr:col><xdr:colOff>0</xdr:colOff><xdr:row>")+(Y+"</xdr:row>");y+="<xdr:rowOff>0</xdr:rowOff>";y+="</xdr:from>";y+='<xdr:ext cx="'+H+'" cy="'+E+'"/>';y+="<xdr:pic>";y+='<xdr:nvPicPr><xdr:cNvPr id="2" name="Picture 1"><a:extLst>';y+='<a:ext uri="{'+R()+'}">';y+='<a16:creationId xmlns:a16="http://schemas.microsoft.com/office/drawing/2014/main" id="{'+R()+'}"/>';y+='</a:ext></a:extLst></xdr:cNvPr><xdr:cNvPicPr><a:picLocks noChangeAspect="1" /></xdr:cNvPicPr></xdr:nvPicPr>';y+="<xdr:blipFill>";
y+='<a:blip xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships" r:embed="rId1"/>';y+="<a:stretch><a:fillRect/></a:stretch>";y+="</xdr:blipFill>";y+='<xdr:spPr><a:prstGeom prst="rect"><a:avLst/></a:prstGeom></xdr:spPr></xdr:pic><xdr:clientData/></xdr:oneCellAnchor></xdr:wsDr>';b.file("xl/drawings/drawing"+n+".xml",y);H=x.content;y=x.content.indexOf("/")+1;x=x.content.substr(y,4).split(";")[0];b.file("xl/drawings/_rels/drawing"+n+".xml.rels",'<?xml version="1.0" encoding="UTF-8"?><Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships"><Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/image" Target="../media/image'+
(t+"."+x+'"/></Relationships>'));H=H.slice(H.indexOf(",")+1);b.file("xl/media/image"+t+"."+x,H,{base64:!0});t++;n++;x=n-1;S+='<drawing r:id="rId'+C+'"/>';G+='<Relationship Id="rId'+C+'" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/drawing" Target="../drawings/drawing'+x+'.xml"/>';T+='<Override PartName="/xl/drawings/drawing'+x+'.xml" ContentType="application/vnd.openxmlformats-officedocument.drawing+xml"/>';C++});G+="</Relationships>";b.file("xl/worksheets/_rels/sheet"+
c+".xml.rels",G);a="";if(d.mergeCells)for(h=Object.entries(d.mergeCells),e=0;e<h.length;e++)f=h[e],a+='<mergeCell ref="'+f[0]+":",v=g.helpers.getCoordsFromColumnName(f[0]),f=[v[0]+f[1][0]-1,v[1]+f[1][1]-1],a+=g.helpers.getColumnNameFromCoords(f[0],f[1])+'"/>';p='<?xml version="1.0" encoding="UTF-8"?><worksheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">'+p+r+("<sheetData>"+m+"</sheetData>");""!==a&&
(p+="<mergeCells>"+a+"</mergeCells>");p+=P;p+=S;p+="</worksheet>";r="xl/worksheets/sheet"+c+".xml";b.file(r,p);return['<Override PartName="/'+r+'" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml" />',T,'<Relationship Id="rId'+c+'" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet" Target="worksheets/sheet'+c+'.xml" />']},aa="thin medium thick dotted dashed double mediumDashed".split(" "),ba=[["solid","1px"],["solid","2px"],["solid",
"3px"],["dotted","1px"],["dashed","1px"],["double","1px"],["dashed","2px"]],ca=function(a){var c="<fonts>";c+='<font><sz val="11"/><color theme="1"/><name val="Calibri"/><family val="2"/><scheme val="minor"/></font>';J.forEach(function(f){if(f){var e="<font>";f.bold&&(e+="<b/>");f.italic&&(e+="<i/>");f.fontSize&&(e+='<sz val="'+f.fontSize+'"/>');f.color&&(e+='<color rgb="FF'+f.color.slice(1)+'"/>');f.fontName&&(e+='<name val="'+f.fontName+'"/>');c+=e+"</font>"}});c+="</fonts>";var b="<fills>";b+=
'<fill><patternFill patternType="none"/></fill><fill><patternFill patternType="gray125"/></fill>';M.forEach(function(f){f&&(f='<fill><patternFill patternType="solid"><fgColor rgb="FF'+(f.slice(1)+'"/>'),b+=f+"</patternFill></fill>")});b+="</fills>";var d=["left","right","top","bottom"],h="<borders>";h+="<border><left/><right/><top/><bottom/><diagonal/></border>";I.forEach(function(f){if(f){var e="<border>";d.forEach(function(v){var u="<"+v;if(3===Object.keys(f[v]).length){var w=ba.findIndex(function(A){return A[0]===
f[v].style&&A[1]===f[v].width});u+=' style="'+aa[w]+'">';u+='<color rgb="FF'+f[v].color.slice(1)+'"/>';u+="</"+v+">"}else u+="/>";e+=u});e+="<diagonal/></border>";h+=e}});h+="</borders>";var p="<numFmts>";O.forEach(function(f){p+='<numFmt numFmtId="'+f[0]+'" formatCode="'+f[1].replace(/"/g,"&#34;")+'"/>'});p+="</numFmts>";var r="<cellXfs>";r+='<xf numFmtId="0" fontId="0" fillId="0" borderId="0" xfId="0"/>';K.forEach(function(f){if(f){var e="<xf";void 0!==f.format&&(e+=' numFmtId="'+f.format+'"');
void 0!==f.font&&(e+=' fontId="'+f.font+'"');void 0!==f.fill&&(e+=' fillId="'+f.fill+'"');void 0!==f.border&&(e+=' borderId="'+f.border+'"');void 0!==f.horizontalAlign||void 0!==f.wrapText?(e+="><alignment",void 0!==f.horizontalAlign&&(e+=' horizontal="'+N[f.horizontalAlign]+'"'),void 0!==f.wrapText&&(e+=' wrapText="1"'),e+="/></xf>"):e+="/>";r+=e}});r+="</cellXfs>";var m='<styleSheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main">'+p+c+b;m+=h;m+='<cellStyleXfs count="1"><xf numFmtId="0" fontId="0" fillId="0" borderId="0"/></cellStyleXfs>';
m+=r;m+="</styleSheet>";a.file("xl/styles.xml",m)},da=function(a,c,b){t=n=1;a.spreadsheet?a=a.spreadsheet:(a=a.jspreadsheet,a={worksheets:Array.isArray(a)?a:[a]});k.file("_rels/.rels",'<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships"><Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="xl/workbook.xml" /></Relationships>');F(a,k);b='<Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types"><Default Extension="png" ContentType="image/png"/><Default Extension="rels" ContentType="application/vnd.openxmlformats-package.relationships+xml" /><Default Extension="xml" ContentType="application/xml" /><Override PartName="/xl/workbook.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml" />';
for(var d='<?xml version="1.0" encoding="UTF-8"?><Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">',h=null,p="",r=0;r<a.worksheets.length;r++){h=r+1;var m=Z(a.worksheets[r],h,k);b+=m[0];p+=m[1];d+=m[2]}ca(k);W(b,p,k);L(d,h,k);k.generateAsync({type:"blob"}).then(function(f){if(window.navigator&&window.navigator.msSaveOrOpenBlob)window.navigator.msSaveOrOpenBlob(f,c.filename);else{var e=document.createElement("a");f=URL.createObjectURL(f);e.href=f;e.setAttribute("download",
c.filename);document.body.appendChild(e);e.click();e.parentNode.removeChild(e)}})},U=0,V=function(a,c){null===g&&(g=this);"object"!==typeof c&&(c={});c.filename||(c.filename="jspreadsheet.xlsx");var b=l.label();b&&(console.log(b),U++,3<U&&alert(b));return new Promise(function(d){da(a,c,d)})};V.license=function(a){l(a)};return V});
